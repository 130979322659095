<div class="seasonal-objects-container">
  <div *ngIf="isLoaderVisible">
    <app-loader></app-loader>
  </div>
  <div class="description">
    <div *ngIf="isChristmas">
      <h1>
        {{'woodenChristmasGlobes' | translate}}
      </h1>
      <p style="text-align: center">
        {{'christmasParagraph1' | translate}}
      </p>

      <p style="margin: 0">
        {{'christmasParagraph2' | translate}}
        {{'christmasParagraph3' | translate}}
      </p>

      <p>
        {{'christmasParagraph4' | translate}}
      </p>

      <p>
        {{'christmasParagraph5' | translate}}
      </p>

      <p style="text-align: right; font-style: italic;">
        {{'christmasParagraph6' | translate}}
      </p>
    </div>
    <div *ngIf="isMartisor">
      <h1>
        {{'martisorH1' | translate}}
      </h1>
<!--      <h3>-->
<!--        {{'martisorH3' | translate}}-->
<!--      </h3>-->

      <p style="text-align: center">
        {{'martisorParagraph1' | translate}}
      </p>

      <p style="margin: 0">
        {{'martisorParagraph2' | translate}}
        {{'martisorParagraph3' | translate}}
        {{'martisorParagraph4' | translate}}
      </p>

      <p style="text-align: center; font-style: italic; margin-top: 12px">
        {{'martisorParagraph5' | translate}}
      </p>


      <button mat-raised-button class="mat-button order center" (click)="toggleOrder()" *ngIf="!isOrderFormVisible">
        {{'wantToOrder' | translate}}
      </button>
      <button mat-button class="mat-button center" (click)="toggleOrder()" *ngIf="isOrderFormVisible">
        {{'hideForm' | translate}}
      </button>


      <div class="form-container" *ngIf="isOrderFormVisible">
        <form #form="ngForm">
          <label for="mName">{{'name' | translate}}:</label>
          <input type="text" name="mName" id="mName" placeholder="{{'martisorNamePlaceholder' | translate}}" #mname>

          <label for="mPhone">{{'phone' | translate}}:</label>
          <input type="phone" name="mPhone" id="mPhone" placeholder="{{'martisorPhonePlaceholder' | translate}}" #mphone>

          <label for="mEmail">{{'email' | translate}}:</label>
          <input type="text" name="mEmail" id="mEmail" placeholder="{{'martisorEmailPlaceholder' | translate}}" #memail>

          <label for="mMessage">{{'order' | translate}}:</label>
          <input type="text" name="Message" id="mMessage" placeholder="{{'martisorOrderPlaceholder' | translate}}"
                 #mmessage>

          <!--        <p class="error">{{errorMessage}}</p>-->
          <p class="error" style="text-align: center">{{errorMessage}}</p>
          <button mat-raised-button (click)="send(form, mname.value, mphone.value, memail.value, mmessage.value)" *ngIf="!isMailSent">{{'sendMessage' | translate}}</button>
        </form>
        <div *ngIf="isMailSent">
          <p style="text-align: center">{{'thanksForEmail' | translate}}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="seasonal-objects">
    <div class="object" *ngFor="let object of objects">

      <div class="painting">
        <div class="painting-overlay" [routerLink]="['/Painting', object.category, object.id]">
          <button mat-button class="details">
            <img alt="detail eye svg" src="assets/other/svg/eye.png" height="40px" class="svg">
          </button>
        </div>

        <img [src]="object.path" alt="Obiecte sezoniere" class="product-image">
      </div>
      <div class="availability-container" *ngIf="isChristmas">
        <div *ngIf="object.availability == 0" class="flex">
          <div class="available"></div>{{'available' | translate}}
        </div>
        <div *ngIf="object.availability == 1" class="flex">
          <div class="not-available"></div>{{'notAvailable' | translate}}
        </div>
      </div>
      <div class="availability-container">
        <p>{{object.name | translate}}</p>
      </div>
    </div>
  </div>
</div>

import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

@Injectable({providedIn: 'root'})

export class ConfigService {
  constructor(private http: HttpClient) {}

  backendAddress = 'https://lupulcreativ.ro/com';
  // backendAddress = 'http://localhost:8000';
  clientSecret = 'rYyRmmWF0yZKFRLJTHVByxwcJNED7C2twu2SnKjR';

  // tslint:disable-next-line:typedef
  GetPaintings(category: string) {
    return this.http.get(this.backendAddress + '/api/get-category?category=' + category);
  }
  // tslint:disable-next-line:typedef
  getPaintingById(id: string){
    return this.http.get(this.backendAddress + '/api/picture/' + id);
  }

  // tslint:disable-next-line:variable-name typedef
  reservePainting(painting_id: string, email: string) {
    // reserve-picture
    const payload = { email, painting_id};
    return this.http.post(this.backendAddress + '/api/reserve-picture', payload);
  }
  // tslint:disable-next-line:typedef
  SendMultipleEmails(email1: string, email2: string, name: string, message: string, artistName: string, buyerName: string ) {
    const emailAddresses = [email1, email2];
    const payload = { email_addresses: emailAddresses, name, message , mail: 'lupulcreativ@gmail.com', artistName, buyerName};
    return this.http.post(this.backendAddress + '/api/send-multiple-emails', payload);
  }
  // tslint:disable-next-line:typedef
  SendEmail(name: string, mail: string, message: string) {
    const params = new HttpParams()
      .set('name', name)
      .set('mail', mail)
      .set('message', message);
    return this.http.get(this.backendAddress + '/api/sendemail', {params});
  }
  // tslint:disable-next-line:typedef
  SaveEmailForMarket(userEmail: string) {
    const payload = { email: userEmail};
    return this.http.post(this.backendAddress + '/api/offers/save-market-email', payload);
  }
  // tslint:disable-next-line:typedef
  Login(emai: string, pass: string) {
    const data = {
      username: emai,
      password: pass,
      grant_type: 'password',
      client_id: 2,
      client_secret: this.clientSecret,
      scope: '*'
    };
    // tslint:disable-next-line:max-line-length
    return this.http.post(this.backendAddress + '/api/oauth/token', data);
  }
  // tslint:disable-next-line:typedef
  Register(firstName: string, lastName: string, pass: string, emaill: string, phoneNumber: string, agreeData: number,
           justMail: number, phoneAndMail: number, roleId: number) {
    // tslint:disable-next-line:max-line-length
    return this.http.post(this.backendAddress + '/api/auth/register',
      {email: emaill, password: pass, first_name: firstName, last_name: lastName, phone_number: phoneNumber,
        agreed_term_conditions: agreeData, agreed_contacted_via_mail: justMail,
        agreed_contacted_via_phone : phoneAndMail, role_id: roleId});
  }
  // tslint:disable-next-line:typedef
  getLoggedUser() {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem('token')}`
    });
    return this.http.get(this.backendAddress + '/api/users/user', {headers});
  }
  // tslint:disable-next-line:typedef
  postFile(file: any) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem('token')}`
    });
    return this.http.post(this.backendAddress + '/api/art-objects/upsert',
      file, {headers});
  }
  // tslint:disable-next-line:typedef
  getFilteredArtObjects(category: any, id: any) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('picture_category', category);
    queryParams = queryParams.append('user_id', id);
    return this.http.get(this.backendAddress + '/api/art-objects/all', {params: queryParams});
  }
  // tslint:disable-next-line:typedef
  getFilterArtObjectsByCategory(category: any){
    let queryParams = new HttpParams();
    queryParams = queryParams.append('picture_category', category);
    return this.http.get(this.backendAddress + '/api/art-objects/all', {params: queryParams});
  }
  // tslint:disable-next-line:typedef
  getFilterArtObjectsByArtist(id: any){
    let queryParams = new HttpParams();
    queryParams = queryParams.append('user_id', id);
    return this.http.get(this.backendAddress + '/api/art-objects/all', {params: queryParams});
  }
  // tslint:disable-next-line:typedef
  getAllArtObjects() {
    return this.http.get(this.backendAddress + '/api/art-objects/all');
  }
  // tslint:disable-next-line:typedef
  getLoggedInArtistArtObjects() {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem('token')}`
    });
    return this.http.get(this.backendAddress + '/api/art-objects/artist-paintings', {headers});
  }
  // tslint:disable-next-line:typedef
  postUpsertDropdowns() { // aduve painting categories
    return this.http.post(this.backendAddress + '/api/art-objects/upsert-dropdowns', '');
  }
  // tslint:disable-next-line:typedef
  deleteArtObject(id: string) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem('token')}`
    });
    return this.http.delete(this.backendAddress + '/api/art-objects/' + id + '/delete', {headers});
  }
  // tslint:disable-next-line:typedef
  postUserEdit(id: string, fisrtName: string, lastName: string, phoneNumber: string,
               password: string) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem('token')}`
    });
    return this.http.post(this.backendAddress + '/api/users/' + id + '/edit',
      {first_name: fisrtName, last_name: lastName, phone_number: phoneNumber, password}, {headers});
  }
  // tslint:disable-next-line:typedef
  getArtObjectById(id: string) {
    return this.http.get(this.backendAddress + '/api/art-objects/' + id + '/view');
  }
  // tslint:disable-next-line:typedef
  getOffersStatuses() {
    return this.http.get(this.backendAddress + '/api/offers/statuses');
  }
  // tslint:disable-next-line:typedef
  getArtists() {
    return this.http.get(this.backendAddress + '/api/users/get-artists');
  }
  // tslint:disable-next-line:typedef
  getUserById(id: string) {
    return this.http.get(this.backendAddress + '/api/users/' + id + '/user-by-id');
  }
  // tslint:disable-next-line:typedef
  getMyOffers() {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem('token')}`
    });
    return this.http.get(this.backendAddress + '/api/offers/my-offers', {headers});
  }
  // tslint:disable-next-line:typedef
  postOfferCreate( body: any) {
    return this.http.post(this.backendAddress + '/api/offers/create', body);
  }
  // tslint:disable-next-line:typedef
  postOfferEdit(id: string, body: any) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem('token')}`
    });
    return this.http.post(this.backendAddress + '/api/offers/' + id + '/edit', body, {headers});
  }
}

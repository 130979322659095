<body>
<header>
  <div class="container">
    <div [className]="isHomeLocation ? 'hero' : 'simple'">

      <div class="menu" id="menu">
        <a routerLink="/" class="logo">
          <img src="assets/other/logo_write.svg" alt="lupul creativ logo" class="logo">
        </a>

        <nav>
          <ul>
            <li>
              <a [matMenuTriggerFor]="hamburgermenu" style="padding: 0; margin-right: -20px">
                <img src="assets/other/menu.svg" alt="hamburger menu" class="hamburger">
              </a>
              <mat-menu #hamburgermenu="matMenu">
                <button mat-menu-item href="#" routerLink="/">{{ 'home' | translate }}</button>
                <button mat-menu-item href="#" routerLink="/Gallery">{{ 'gallery' | translate }}</button>
                <button mat-menu-item href="#" routerLink="/Events">{{ 'events' | translate }}</button>
                <button mat-menu-item href="#" routerLink="/Contact">{{ 'contact' | translate }}</button>
                <button mat-menu-item href="#" routerLink="/About">{{ 'about' | translate }}</button>
                <button mat-menu-item href="#" routerLink="/Market" style="background-color: #28a77a">Market</button>
              </mat-menu>
            </li>
          </ul>
        </nav>

        <nav class="x">
          <ul>
            <li><a href="#" routerLink="/">{{ 'home' | translate }}</a></li>
            <li><a href="#" routerLink="/Gallery" (click)="test()">{{ 'gallery' | translate }}</a></li>
            <li><a href="#" routerLink="/Events">{{ 'events' | translate }}</a></li>
            <li><a href="#" routerLink="/Contact">{{ 'contact' | translate }}</a></li>
            <li><a href="#" routerLink="/About">{{ 'about' | translate }}</a></li>
            <li><a href="#" routerLink="/Market"><span matBadge="!" matBadgeOverlap="false">Market</span></a></li>

            <!--            <mat-menu #menu="matMenu">-->
<!--              <button mat-menu-item (click)="UpdatePaintingCathegory(1)">{{ 'galleryCanvasPaintings' | translate }}</button>-->
<!--              <button mat-menu-item (click)="UpdatePaintingCathegory(2)">{{ 'galleryWallPaintings' | translate }}</button>-->
<!--              <button mat-menu-item (click)="UpdatePaintingCathegory(3)">{{ 'galleryMugPaintings' | translate }}</button>-->
<!--              <button mat-menu-item (click)="UpdatePaintingCathegory(6)">{{ 'galleryHouseNumbers' | translate }}</button>-->
<!--              <button mat-menu-item (click)="UpdatePaintingCathegory(4)">{{ 'gallerySpecialGifts' | translate }}</button>-->
<!--              <button mat-menu-item (click)="UpdatePaintingCathegory(5)">{{ 'galleryMenus' | translate }}</button>-->
<!--            </mat-menu>-->
          </ul>
        </nav>

        <div class="language">
          <select name="language" id="language-r" (change)="languageChange($event)" [ngModel]="langId">
            <option value="0">RO</option>
            <option value="1">EN</option>
          </select>
        </div>
      </div>

      <div  class="description">
        <button mat-raised-button [routerLink]="'Gallery'" class="simple-button">{{ 'heroButtonMessage' | translate }}</button>
<!--        <button mat-raised-button [routerLink]="'SeasonalObjects'" class="simple-button">{{ 'heroButtonMessageSezon' | translate }}</button>-->
      </div>
    </div>

  </div>
</header>

<div class="container" id="content-container">
  <router-outlet (activate)="changedHref()"></router-outlet>
</div>
<div class="cookie" *ngIf="isCookieVisible">
  <p>🍪🍪 {{'weUseCookies' | translate}}</p>
  <p>{{'cookieText' | translate}}</p>
  <button mat-button (click)="acceptCookies()">{{'acceptCookie' | translate}}</button>
  <button mat-button (click)="redirect()">{{'knowMoreAboutCookie' | translate}}</button>
</div>

<footer class="footer">
  <img src="assets/logo/lupusor_nowrite.svg" alt="lupul creativ logo" class="wolf-logo">


<!--  <p>-->
<!--    <img (click)="Open('facebook.com/lupulcreativ')" src="assets/other/facebook.svg" class="footer-logos">-->
<!--    <img (click)="Open('instagram.com/lupulcreativ')" src="assets/other/instagram.svg" class="footer-logos">-->
<!--  </p>-->

  <div class="info-table">
    <div class="info-td">
      <div class="imgs">
        <img (click)="Open('facebook.com/lupulcreativ')" src="assets/other/facebook.svg" alt="facebook icon" class="footer-logos">
        <img (click)="Open('instagram.com/lupulcreativ')" src="assets/other/instagram.svg" alt="instagram icon" class="footer-logos">
      </div>
      <p>lupulcreativ@gmail.com</p>
      <p>+40 740 241 423</p>
    </div>
    <div class="info-td">
      <p><a href="#" routerLink="/TermsConditions">{{'termsAndConditionsAccount' | translate}}</a></p>
      <p><a href="#" routerLink="/TermsConditions">{{'confidentialityPolicy' | translate}}</a></p>
      <p><a href="#" routerLink="/TermsConditions">{{'cookiePolicyTitle' | translate}}</a></p>
    </div>
    <div class="info-td">
      <p>Lupul Creativ SRL</p>
      <p>CUI: 50083533</p>
      <p>J32/957/2024</p>
    </div>
  </div>
  <div class="sol-sal">
    <a href="https://anpc.ro/ce-este-sal/" target="_blank">
      <img src="https://wpfitness.eu/wp-content/uploads/2022/10/anpc-sal.png" alt="sal" class="sal">
    </a>
    <a href="https://ec.europa.eu/consumers/odr" target="_blank">
      <img src="https://wpfitness.eu/wp-content/uploads/2022/10/anpc-sol.png" alt="sol" class="sol">
    </a>
  </div>
  <table class="footer-table">
    <tr class="footer-tr">
      <td class="footer-td">
        © Lupul Creativ - 2024
      </td>
      <td class="footer-td">
        <div class="developed-by">
          Powered by <a class="scr-link" target="_blank" href="https://scriptics.ro/">Scriptics</a>
        </div>
      </td>
    </tr>
  </table>
  <div class='wave-container'>
    <div class='wave'></div>
    <div class='wave wave2'></div>
    <div class='wave wave3'></div>
  </div>
</footer>
</body>

import { Component, OnInit } from '@angular/core';
import {ConfigService} from '../config/config.service';
import {Paintings} from '../shared/Paintings';
import {TranslateService} from '@ngx-translate/core';
import {Meta, Title} from '@angular/platform-browser';
import {NgForm} from '@angular/forms';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-seasonal-objects',
  templateUrl: './seasonal-objects.component.html',
  styleUrls: ['./seasonal-objects.component.scss']
})
export class SeasonalObjectsComponent implements OnInit {

  // tslint:disable-next-line:variable-name
  constructor(private api: ConfigService, private translate: TranslateService, private meta: Meta,
              // tslint:disable-next-line:variable-name
              private meta_title: Title, private recaptchaV3Service: ReCaptchaV3Service, private snackBar: MatSnackBar) { }

  // tslint:disable-next-line:ban-types
  objects: any = [
    {
      id: 148,
      path: '',
      name: '',
      category: '',
      tags: '',
      availability: 0,
      price: '',
      dimension: '',
      description: '',
      created_at: null,
      updated_at: null
    }
  ];
  isLoaderVisible = false;
  isMartisor  = true;
  isChristmas = false;
  isOrderFormVisible = false;
  errorMessage = '';
  isMailSent = false;

  toggleOrder(): void {
    this.isOrderFormVisible = !this.isOrderFormVisible;
  }

  send(form: NgForm, name: string, phone: string, email: string, message: string): void {
    if (form.invalid) {
      for (const control of Object.keys(form.controls)) {
        form.controls[control].markAsTouched();
      }
      return;
    }

    this.recaptchaV3Service.execute('submitContactForm')
      .subscribe((token: string) => {
          // console.log(`Token [${token}] generated`);
          this.sendMessage(name, phone, email, message);
        },
        error => {
          console.log('error', error);
          this.snackBar.open('Recaptcha is not working properly', '',
            {duration: 3000, panelClass: 'error-snack-bar', horizontalPosition: 'end', verticalPosition: 'top'});
        });
  }

  // tslint:disable-next-line:typedef
  validateEmail(email: any) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  sendMessage(name: string, phone: string, email: string, message: string): void {
    if (name === '' || email === '' || message === '' || phone === '') {
      this.translate.get('completeRequiredFields').subscribe( msg => {
        this.errorMessage =  msg;
      });
    } else {
      if (this.validateEmail(email)){
        this.isLoaderVisible = true;
        this.errorMessage = '';
        message = 'ne tel: ' + phone + ' si comanda: ' + message;
        this.api.SendEmail(name, email, message).subscribe(
          data => {
            this.isLoaderVisible = false;
            this.isMailSent = true;
          },
          error => {
            console.log('oops', error);
            this.translate.get('errorOccurred').subscribe(msg => {
              this.errorMessage = msg;
            });

            this.isLoaderVisible = false;
            this.isMailSent = false;
          });
      }
      else {
        this.translate.get('invalidEmail').subscribe(msg => {
          this.errorMessage = msg;
        });
      }
    }
  }

  filerByAvailability(): void {
    const availableItems: any[] = [];
    const unavailableItems: any[] = [];
    // @ts-ignore
    this.objects.forEach((object: any) => {
      if (object.availability === 0) {
        availableItems.push(object);
      } else {
        unavailableItems.push(object);
      }
    });

    this.objects = availableItems;
    if (this.isChristmas) {
      unavailableItems.forEach(element => {
        this.objects.push(element);
      });
    }
    this.objects.reverse();
  }

  getPaintings(): void {
    this.api.GetPaintings('3').subscribe(
      data => {
        // @ts-ignore
        this.objects = data;
        this.filerByAvailability();
        this.isLoaderVisible = false;
      },
      error => {
        console.log('oops', error);
        this.isLoaderVisible = false;
      }
    );
  }
  seeDetails(id: any): void {
    console.log('see datils');
  }

  ngOnInit(): void {
    this.meta_title.setTitle('Mărțișoare - Lupul Creativ');
    this.meta.updateTag({ name: 'description', content: 'Mărțișoare handmade, care se schimbă în fiecare an' });
    this.meta.updateTag({ name: 'keywords', content: 'mărțișoare, martisoare, lut poimeric, handmade, pictate' });
    this.isLoaderVisible = true;
    this.getPaintings();
  }

}
